import Vue from 'vue'
import App from './App.vue'
import './assets/saira-extra-condensed.css';

import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
  en: {
    message: {
      t1: "Shove something up your butt",
      t2: "Lick an object lasciviously",
      t3: "Put on a skirt",
      t4: "Kneel in a submissive position",
      t5: "Paint your lips red"
    },
      start: "Start Game",
      title: "Chastity Slot Machine",
  },
  de: {
    message: {
      t1: "Schieb dir was in den Po",
      t2: "Lecke lasziv an einem Gegenstand",
      t3: "Zieh dir einen Rock an",
      t4: "Knie dich in eine devote Stellung",
      t5: "Mal dir die Lippen rot an."
    },

      start: "Spiel starten",
      title: "Keuschheits Slot Machine",
  }
};

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages
});


Vue.config.productionTip = false

new Vue({
  i18n,
  render: h => h(App),
}).$mount('#app')
