<template>
  <div>
    <button @click="switchLanguage" class="language-switch">{{ currentLanguage === 'en' ? 'DE' : 'EN' }}</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentLanguage: this.$i18n.locale
    };
  },
  methods: {
    switchLanguage() {
      this.currentLanguage = this.currentLanguage === 'en' ? 'de' : 'en';
      this.$i18n.locale = this.currentLanguage;
    }
  }
}
</script>

<style scoped>

.language-switch {
  background-color: #7D5B7D;
  color: white;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  font-size: 1em;
  padding: 1rem;
  margin-bottom: 2em;
  cursor: pointer;
}
</style>