<template>
  <div id="app">
     <header>
      <h1>{{  $t('title') }}</h1>
    </header>
    <language-button></language-button>
    <div class="section">
    <slot-machine
    class="slot-machine"
      :list="customList"
      :trigger="trigger"
      :width="500"
      :height="200"
      :responsive="true"
    ></slot-machine></div>
    <button @click="startGame" class="startButton">{{ $t('start') }}</button>
    <footer><a href="//mykinkgames.com/imprint">Imprint</a></footer>
  </div>
</template>

<script>
import SlotMachine from './components/SlotMachine.vue';  // Stellen Sie sicher, dass der Pfad korrekt ist
import LanguageButton from './components/LanguageButton.vue';  // Stellen Sie sicher, dass der Pfad korrekt ist

export default {
  name: 'App',
  components: {
    SlotMachine,
    LanguageButton,
  },
  data() {
    return {
      customList: this.getCustomList(),
      trigger: null,  // Initial null setzen, wird durch Button-Klick gesetzt
    };
  },
  methods: {
    startGame() {
      this.trigger = new Date();  // Setzt den trigger auf die aktuelle Zeit, löst die Slot Machine aus
    },
    getCustomList() {
      const baseStyle = {
        fontSize: 32, 
        fontFamily: 'Saira Extra Condensed', 
        align: 'center',
        verticalAlign: 'middle',
      };
      const colors = ["#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"];
      return Object.keys(this.$t('message')).filter(key => key.startsWith('t')).map((key, index) => ({
        text: this.$t(`message.${key}`),
        color: colors[index % colors.length],
        ...baseStyle
      }));
    }
  },
  watch: {
    '$i18n.locale': function () {
      this.customList = this.getCustomList(); // Aktualisieren Sie die Liste, wenn die Sprache geändert wird
    }
  }
}
</script>

<style scoped>
#app {
  text-align: center;
  margin-top: 50px;
}

.slot-machine {
  background-color: #7D5B7D;
  width: 90vw;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

* {
  font-family: 'Saira Extra Condensed', sans-serif;
}

.startButton {
  background-color: #7D5B7D;
  color: white;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  font-size: 1.75em;
}

h1 {
  font-size: 3.5em;
  color: #7D5B7D;
}

.section {
  display: flex;
  justify-content: center;
}

footer {
  position: absolute; 
  bottom: 0; 
  padding: 10px 0;
}
footer>a {
  text-decoration: none;
}
</style>
